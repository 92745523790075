import { getRoute } from "~/lib/routes";

export default defineNuxtRouteMiddleware(async () => {
	const nuxtApp = useNuxtApp();
	if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;

	const contextToken = useCookie("sw-context-token");

	const { isEmpty, isReady } = process.server
		? useShopwareCartImplementation(contextToken.value as string | undefined)
		: useShopwareCart();

	if (!isReady.value) {
		const timeout = setTimeout(() => {
			useBugsnag().notify("Shopware cart is not ready after 7 seconds.");
		}, 7000);
		await until(isReady).toBeTruthy();
		clearTimeout(timeout);
	}

	if (isEmpty.value) {
		return navigateTo(getRoute("assortment"));
	}
});
